import $http from '../http'

// 获取人员任务完成度
export const getCompleteness = data => {

    return $http.get(`/api/v1/admin/collect/users`, data)
}

// 获取人员当月应拜访客户列表
export const getCurrentTasks = data => {

    const params = JSON.parse(JSON.stringify(data))
    delete params.id

    return $http.get(`/api/v1/admin/collect/user/${data.id}/thismonth`, params)
}

// 获取人员历史应拜访客户列表
export const getHistoryTasks = data => {

    const params = JSON.parse(JSON.stringify(data))
    delete params.id

    return $http.get(`/api/v1/admin/collect/user/${data.id}`, params)
}

// 获取集团统计数据
export const getGroupData = data => {

    return $http.get(`/api/v1/admin/score/statistics`, data)
}

// 获取工作完成率报告
export const getWorkReport = data => {

    return $http.get(`/api/v1/colloct/jobcompletion`, data)
}

// 获取工单数据统计
export const getOrderReport = data => {
    return $http.get(`/api/v1/colloct/avgworkon`, data)
}

// 获取问题分析报告
export const getQuestionReport = data => {
    return $http.get(`/api/v1/colloct/unqualified/count`, data)
}

// 获取问题分析报告
export const getGroupReport = data => {
    return $http.get(`/api/v1/colloct/unqualified/countbygroup`, data)
}

<template>
  <div class="QuestionReport">
    <div class="report-header">
      <div class="report-header-top">
        <module-title text="筛选项"></module-title>
      </div>
      <div class="report-header-bottom">
        <div class="report-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
            :rules="rules"
            ref="ruleForm"
          >
            <a-form-model-item label="集团品牌">
              <searchSelect
                :flag="false"
                :bid="form.bid"
                :iptWidth="180"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>

            <a-form-model-item label="起止时间段" class="date">
              <a-date-picker
                v-model="form.starttime"
                :disabled-date="disabledStartDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请选择开始时间"
                @openChange="handleStartOpenChange"
              />
              <span> ── </span>
              <a-date-picker
                v-model="form.endtime"
                :disabled-date="disabledEndDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请选择结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="report-header-bottom-right" @click="getReport">汇总</div>
      </div>
    </div>
    <div class="report-main">
      <div class="report-main-top">
        <div class="serve">
          <div><module-title text="服务不符合项汇总统计"></module-title></div>
          <div>
            <div id="serve" style="height: 500px"></div>
          </div>
        </div>
        <div class="rains">
          <div><module-title text="服务不符合汇总详情"></module-title></div>
          <div>
            <div id="rains" style="height: 500px"></div>
          </div>
        </div>
      </div>
      <div class="report-main-bottom">
        <div class="rains-ratio">
          <div><module-title text="关键项不符合汇总统计"></module-title></div>
          <div>
            <div id="ratio" style="height: 500px"></div>
          </div>
        </div>
        <div class="score-ratio">
          <div>
            <module-title text="关键项不符合汇总详情"></module-title>
          </div>
          <div>
            <div id="score" style="height: 500px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionReport, getGroupReport } from "../common/api/statistics";
import ModuleTitle from "../components/ModuleTitle";
import moment, { min } from "moment";
import SearchSelect from "../components/SearchSelect.vue";

export default {
  name: "QuestionReport",
  props: {},
  components: {
    ModuleTitle,
    SearchSelect,
  },
  data() {
    return {
      disabled: false,
      endOpen: false,
      rules: {
        rules: {
          starttime: [
            {
              validator: this.validateStarttime,
              trigger: "blur",
            },
          ],
          endtime: [
            {
              validator: this.validateEndtime,
              trigger: "blur",
            },
          ],
        },
      },
      form: {
        bid: undefined,
        starttime: null,
        endtime: null,
        crux: 1,
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 20 },
      QuestionReport: [],
      groupReport: [],
    };
  },
  methods: {
    async getReport() {
      if (!this.form.bid) {
        this.$message.info("请选择集团品牌");
      } else if (!this.form.starttime && !this.form.endtime) {
        this.$message.info("请选择起止时间段");
      } else if (this.form.starttime && !this.form.endtime) {
        this.$message.info("请选择结束时间");
      } else if (!this.form.starttime && this.form.endtime) {
        this.$message.info("请选择开始时间");
      } else {
        let n1 = 0,
          n2 = 0,
          data1 = [],
          data2 = [],
          data3 = [],
          data4 = [],
          data5 = [],
          data6 = [];

        this.form.crux = 0;
        const res1 = await getQuestionReport(this.form);
        const res2 = await getGroupReport(this.form);
        console.log(res1, res2);
        if (!res2.data.length) {
          this.resetPie("serve");
          this.resetBar("rains", "单位：%");
        } else {
          this.QuestionReport = res1.data;
          this.groupReport = res2.data;

          res2.data.forEach((v) => {
            let obj = {
              name: v.groupName,
              value: v.percentage,
            };

            data1.push(obj);
            n1 += v.percentage;
          });
          if (n1 < 100) {
            let value1 = 100 - n1;
            data1.push({ name: "其它", value: value1 });
          }

          this.setGroupReport(data1);
          let arr = [];
          if (res2.data.length > 4) {
            arr = res2.data.splice(0, 4);
          } else {
            arr = res2.data;
          }

          arr.forEach((v, i1) => {
            data2.push(i1 + 1 + "." + v.groupName);
            data3.push({
              value: v.percentage,
              itemStyle: { color: "#643386" },
            });
            v.items.forEach((i, i2) => {
              if (i.itemName.includes("\n")) {
                data2.push(
                  i1 + 1 + "." + (i2 + 1) + " " + i.itemName.split("\n")[0]
                );
              } else {
                data2.push(i1 + 1 + "." + (i2 + 1) + " " + i.itemName);
              }

              data3.push({
                value: i.PERCENTAGE,
                itemStyle: { color: "#d04d67" },
              });
            });
          });
          console.log(data2, data3, 11111);

          this.setReport(data2, data3);
        }

        this.form.crux = 1;
        const res3 = await getQuestionReport(this.form);
        const res4 = await getGroupReport(this.form);
        console.log(res3, res4, "关键项");

        if (!res4.data.length) {
          this.resetPie("ratio");
          this.resetBar("score", "数量");
          if (!res2.data.length) {
            this.$message.info("暂无数据");
          }
        } else {
          let arr1 = [],
            arr2 = [];
          if (res3.data.length > 4) {
            arr1 = res3.data.splice(0, 4);
          } else {
            arr1 = res3.data;
          }
          arr1.forEach((v) => {
            let obj = {
              name: v.itemName,
              value: (v.percentage * res4.data[0].percentage) / 100,
            };

            data4.push(obj);
            n2 += v.percentage;
          });

          if (n2 < 100) {
            let value2 = 100 - n2;

            data4.push({
              name: "其它",
              value: (value2 * res4.data[0].percentage) / 100,
            });
          }

          if (res4.data[0].percentage < 100) {
            let value3 = 100 - res4.data[0].percentage;
            data4.unshift({ name: "正常", value: value3 });
          }
          if (res3.data.length > 12) {
            arr2 = res3.data.splice(0, 12);
          } else {
            arr2 = res3.data;
          }
          arr2.forEach((v) => {
            data5.push(v.itemName);
            data6.push(v.count);
          });
          this.setKeyGroupReport(data4);
          this.setKeyReport(data5, data6);
        }
      }
    },
    setGroupReport(data) {
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("serve")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("serve"));
      }
      const option = {
        // title: {
        //   text: "Referer of a Website",
        //   subtext: "Fake Data",
        //   left: "center",
        // },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          orient: "vertical",
          left: "right",
          formatter: function (name) {
            // 如果文本长度超过5个字符，则截断并添加省略号
            if (name.length > 10) {
              name = name.substring(0, 10) + "...";
            }
            return name;
          },
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            startAngle: "180",
            label: {
              show: false,
              normal: {
                show: true,
                // position: "inside",
                formatter: "{d}%",
              },
            },
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    setReport(data2, data3) {
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("rains")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("rains"));
      }
      const option = {
        xAxis: {
          name: "名称",
          type: "category",
          data: data2,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
          axisLabel: {
            formatter: function (params) {
              var str = ""; // 最终拼接成的字符串
              var paramsLen = params.length; // 获取每项文字的个数
              var len = 2; // 每行能显示的字的个数（根据实际情况自己设置）
              var rowNumber = Math.ceil(paramsLen / len); // 换行的话，需要显示几行，向上取整
              if (paramsLen > len) {
                //大于设定的len就换行，不大于就不变化
                for (var i = 0; i < rowNumber; i++) {
                  var temp = ""; // 表示每一次截取的字符串
                  var start = i * len; // 开始截取的位置
                  var end = start + len; // 结束截取的位置
                  if (i == rowNumber - 1) {
                    // 最后一次不换行
                    temp = params.substring(start, paramsLen);
                  } else {
                    // 每一次拼接字符串并换行
                    temp = params.substring(start, end) + "\n";
                  }
                  str += temp; // 最终拼成的字符串
                }
              } else {
                // 给新的字符串赋值
                str = params;
              }
              return str; //返回字符串
            },
          },
        },
        yAxis: {
          name: "单位：%",
          type: "value",
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: "40%",
        },

        series: [
          {
            data: data3,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    setKeyGroupReport(data) {
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("ratio")
      );
      console.log(myChart, 111111111111);

      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("ratio"));
      }
      console.log(111111111111);

      const option = {
        // title: {
        //   text: "Referer of a Website",
        //   subtext: "Fake Data",
        //   left: "center",
        // },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          orient: "vertical",
          left: "right",
          formatter: function (name) {
            // 如果文本长度超过5个字符，则截断并添加省略号
            if (name.length > 10) {
              name = name.substring(0, 10) + "...";
            }
            return name;
          },
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            startAngle: "180",
            label: {
              show: false,
              normal: {
                show: true,
                // position: "inside",
                formatter: "{d}%",
              },
            },
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },

    setKeyReport(data5, data6) {
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("score")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("score"));
      }
      const option = {
        xAxis: {
          name: "名称",
          type: "category",
          data: data5,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
          axisLabel: {
            formatter: function (params) {
              var str = ""; // 最终拼接成的字符串
              var paramsLen = params.length; // 获取每项文字的个数
              var len = 2; // 每行能显示的字的个数（根据实际情况自己设置）
              var rowNumber = Math.ceil(paramsLen / len); // 换行的话，需要显示几行，向上取整
              if (paramsLen > len) {
                //大于设定的len就换行，不大于就不变化
                for (var i = 0; i < rowNumber; i++) {
                  var temp = ""; // 表示每一次截取的字符串
                  var start = i * len; // 开始截取的位置
                  var end = start + len; // 结束截取的位置
                  if (i == rowNumber - 1) {
                    // 最后一次不换行
                    temp = params.substring(start, paramsLen);
                  } else {
                    // 每一次拼接字符串并换行
                    temp = params.substring(start, end) + "\n";
                  }
                  str += temp; // 最终拼成的字符串
                }
              } else {
                // 给新的字符串赋值
                str = params;
              }
              return str; //返回字符串
            },
          },
        },
        yAxis: {
          name: "数量",
          type: "value",
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: "40%",
        },

        series: [
          {
            data: data6,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            itemStyle: { color: "#d04d67" },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },

    groupChange(e) {
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
    calculateMonthDifference(startDateString, endDateString) {
      var start = new Date(startDateString); // 将起始日期字符串转换为Date对象
      var end = new Date(endDateString); // 将结束日期字符串转换为Date对象

      var timeDiff = Math.abs(end.getTime() - start.getTime()); // 获取时间差（单位：毫秒）
      var monthDiff = Math.ceil(timeDiff / (30 * 24 * 60 * 60 * 1000)); // 根据每个月平均天数进行近似计算

      return monthDiff; // 返回月份差值
    },
    disabledStartDate(startValue) {
      if (this.form.endtime) {
        return (
          startValue >
          moment(this.form.endtime, "YYYY-MM-DD")
            .subtract(-1, "days")
            .startOf("day")
        );
      }
    },
    disabledEndDate(endValue) {
      if (this.form.starttime) {
        // console.log(endValue.format("YYYY-MM-DD"));
        return (
          endValue <
          moment(this.form.starttime, "YYYY-MM-DD")
            .subtract(1, "days")
            .endOf("day")
        );
      }
    },
    handleStartOpenChange(open) {
      if (!open && !this.form.endtime) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    resetPie(id) {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(id));
      }
      const option = {
        series: [
          {
            type: "pie",
            data: [],
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    resetBar(id, name) {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(id));
      }
      const option = {
        xAxis: {
          name: "名称",
          type: "category",
          data: [],
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
        },
        yAxis: {
          name,
          type: "value",

          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
            symbolOffset: 10,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: "10%",
        },

        series: [
          {
            data: [0],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
  mounted() {
    this.resetPie("serve");
    this.resetBar("rains", "单位：%");
    this.resetPie("ratio");
    this.resetBar("score", "数量");
  },
};
</script>

<style scoped lang="scss">
.QuestionReport {
  .report-header {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 0 30px 24px;

    .report-header-bottom {
      display: flex;
      align-items: center;
      padding: 24px 50px 0 0;
      .report-header-bottom-left {
        .ant-form::v-deep {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 50px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            &.date {
              margin-right: 90px;
            }

            .ant-input,
            .ant-select,
            .ant-calendar-picker {
              width: 150px;
            }

            .ant-calendar-picker:hover {
              .ant-input {
                border-color: #83ba7b !important;
              }
            }
          }
        }
      }
      .report-header-bottom-right {
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .report-main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .report-main-top {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .serve,
      .rains {
        width: 49%;
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 16px;
      }
    }
    .report-main-bottom {
      display: flex;
      justify-content: space-between;
      .rains-ratio,
      .score-ratio {
        width: 49%;
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 16px;
      }
    }
    // .rains,
    // .score-ratio {
    //   margin-top: 20px;
    // }
  }
}
</style>

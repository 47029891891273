export const routerMap = {
    '/client': [{
        path: "",
        meta: {
            title: "客户管理",
        },
    }, {
        path: "/client",
        meta: {
            title: "终端门店管理",
        },
    }],
    '/group': [{
        path: "",
        meta: {
            title: "客户管理",
        },
    }, {
        path: "/group",
        meta: {
            title: "集团客户管理",
        },
    }],
    "/order": [{
        path: "/order",
        meta: {
            title: "工单管理",
        },
    }],
    "/ques": [{
        path: "/ques",
        meta: {
            title: "回访管理",
        },
    }],
    "/user": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        meta: {
            title: "用户管理",
        },
    }, {
        path: "/user",
        meta: {
            title: "用户列表",
        },
    }],
    "/visitor": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        meta: {
            title: "用户管理",
        },
    }, {
        path: "/visitor",
        meta: {
            title: "访客列表",
        },
    }],


    "/question": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        path: "/question",
        meta: {
            title: "问题管理",
        },
    }, {
        path: "/questiondetails",
        meta: {
            title: "问题详情",
        },
    }],
    "/question": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        path: "/question",
        meta: {
            title: "问题管理",
        },
    }],
    "/tag": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        path: "/tag",
        meta: {
            title: "Tag管理",
        },
    }],
    "/template": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        path: "/template",
        meta: {
            title: "问卷模板管理",
        },
    }],
    "/createtemplate": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        path: "/template",
        meta: {
            title: "问卷模板管理",
        },
    }, {
        path: "/createtemplate",
        meta: {
            title: "新增问卷模板",
        },
    }],
    "/completeness": [{
        path: "",
        meta: {
            title: "系统管理",
        },
    }, {
        meta: {
            title: "用户管理",
        },
    }, {
        path: "/completeness",
        meta: {
            title: "人员完成度",
        },
    }],
    "/statistical": [{
        path: "",
        meta: {
            title: "统计管理",
        },
    }, {
        path: "/statistical",
        meta: {
            title: "回访报告评分统计",
        },
    }],
    "/workreport": [{
        path: "",
        meta: {
            title: "统计管理",
        },
    }, {
        path: "/workreport",
        meta: {
            title: "工单完成率报告",
        },
    }],
    "/questionreport": [{
        path: "",
        meta: {
            title: "统计管理",
        },
    }, {
        path: "/questionreport",
        meta: {
            title: "问题分析报告",
        },
    }],
    "/orderreport": [{
        path: "",
        meta: {
            title: "统计管理",
        },
    }, {
        path: "/orderreport",
        meta: {
            title: "工单数据统计",
        },
    }],
}

export const pathMap = {
    client: ['/client', '/group'],
    system: ['/user', '/visitor', '/completeness', '/question', '/tag', '/template', '/createtemplate'],
    statistics: ['/statistical', '/workreport', '/questionreport', '/orderreport']
}

